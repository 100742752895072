import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Chip, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import InvoiceDetailsInfo from "./invoiceDetailsInfo";
import InvoicePaymentInfo from "./invoicePaymentInfo";
import {useParams} from "react-router-dom";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import PaymentMethodFragment from "../../../Delivery/PaymentMethod/components/paymentMethodFragment";
import {PaymentDto} from "../../../features/accountingTransactionPayment/accountingTransactionPayment-api";
import {useQuery} from "../../../../utils/helper.utils";
import {
  AccountingTransactionGqlDto,
  DocumentTemplateType,
  PayInvoiceQueryParams,
} from "../../../features/accountingTransaction/accountingTransaction-api";
import {useGetInvoice} from "../hooks/useGetInvoice";
import toast from "react-hot-toast";
import {ContactPaymentMethodDto} from "../../../features/paymentMethod/paymentMethod-api";
import {usePayInvoice} from "../hooks/usePayInvoice";
import {useAppSelector} from "../../../store";
import {getInvoiceStatusColors} from "../../AllInvoices/components/invoiceCard";
import PaymentCardForm, {
  PaymentCardFormValues,
} from "../../../Delivery/PayAndPrintLabel/components/paymentCardForm";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";

const boxStyle = {
  border: "1px solid #2F81EF",
  borderRadius: "16px",
  boxShadow: "none",
};

export default function InvoiceDetails() {
  const token = useAppSelector((state) => state.authState.access_token);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const params = useParams();
  const [invoice, setInvoice] = useState<AccountingTransactionGqlDto>();
  const [payments, setPayments] = useState<PaymentDto[]>();
  const [isLoadingPayInvoice, setIsLoadingPayInvoice] =
    useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<ContactPaymentMethodDto | null>(null);
  const [loading, setLoading] = useState(false);

  const invoiceStateId = Number(params.invoiceId);
  const {payment_intent} = useQuery() as PayInvoiceQueryParams;
  const isPaymentConfirmed = useRef(false);
  const workflowId = useAppSelector(
    (state) =>
      state?.organizationConfigState?.workflows?.documentInvoiceWorkflowId,
  );

  const [runWorkflow] = useWorkflowExecutionMutation();

  const {getRenderedDocumentLink, getInvoiceGql} = useGetInvoice();

  const {payInvoice, confirmInvoicePayment} = usePayInvoice(invoiceStateId);

  const loadInvoice = () => {
    const invoiceId = Number(invoiceStateId);
    if (invoiceId > 0) {
      getInvoiceGql(invoiceId)
        .then((response: any) => {
          if (response.data && response.data?.accountingTransaction) {
            const invoice = response.data?.accountingTransaction;
            setInvoice(invoice);
            setPayments(invoice?.payments);
          }
        })
        .catch(() => toast.error(t("toasts.error")));
    }
  };

  const showSimpleCreditCardForm =
    useAppSelector(
      (state: any) =>
        state.organizationConfigState?.modules?.invoices
          ?.showSimpleCreditCardForm,
    ) ?? false;

  useEffect(() => {
    loadInvoice();
  }, [invoiceStateId]);

  async function handleDownloadPDF() {
    if (invoice && invoice.transactionNumber) {

      setLoading(true);

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: workflowId,
        values: {
          variables: {
            accountingTransactionId: invoice.accountingTransactionId,
          },
        },
      };

      try {
        const workflowResult: any = await runWorkflow(executeWorkflowApiArgs);
  
        const url = workflowResult?.data?.outputs?.documentUrl;
        const name = workflowResult?.data?.outputs?.documentName;
        
        const response = await fetch(url, { referrerPolicy: "no-referrer" });
  
        if (!response.ok) {
          throw new Error("Failed to download document.");
        }
  
        const blob = await response.blob();
        const downloadLink = document.createElement("a");
        const blobUrl = window.URL.createObjectURL(blob);
  
        downloadLink.href = blobUrl;
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl);
  
      } catch (error: unknown) {
        const message = error instanceof Error ? error.message : "Failed to download document.";
        toast.error(message);
      } finally {
        setLoading(false);
      }
    }
  }

  const handlePayInvoice = () => {
    setIsLoadingPayInvoice(true);
    if (selectedPaymentMethod?.contactPaymentMethodId)
      payInvoice(
        {
          contactPaymentMethodId: selectedPaymentMethod?.contactPaymentMethodId,
        },
        window.location.href,
      )
        .then((response: any) => {
          if (response?.data && response?.data?.redirectToUrl)
            window.open(response?.data?.redirectToUrl, "_self");
        })
        .finally(() => setIsLoadingPayInvoice(false));
  };

  const handleConfirmInvoicePayment = () => {
    if (payment_intent) {
      confirmInvoicePayment(payment_intent).then(() => {
        loadInvoice();
      });
    }
  };

  const handlePayInvoiceByCardSubmit = async (
    values: PaymentCardFormValues,
  ) => {
    setIsLoadingPayInvoice(true);
    return payInvoice(
      {
        card: {
          number: values.cardNumber,
          cardHolder: values.holderName,
          expire: values.expDate,
          cvv: values.code,
        },
      },
      window.location.href,
    )
      .then((response: any) => {
        if (response?.data && response?.data?.redirectToUrl)
          window.open(response?.data?.redirectToUrl, "_self");
      })
      .finally(() => {
        setIsLoadingPayInvoice(false);
      });
  };

  useEffect(() => {
    if (isPaymentConfirmed.current) return;
    isPaymentConfirmed.current = true;
    handleConfirmInvoicePayment();
  }, [payment_intent]);

  return (
    <Grid>
      <Grid
        container
        columns={{xs: 6, md: 12}}
        justifyContent={{md: "space-between", xs: "flex"}}
        direction={isDesktop ? "row" : "column"}
        columnSpacing={0}
      >
        <Grid
          mt={{md: 1, xs: 3}}
          container
          direction={isDesktop ? "row" : "column"}
          columnSpacing={0}
        >
          <Grid>
            <Typography
              variant={isDesktop ? "h2" : "body1"}
              display="inline-flex"
              flexWrap={"wrap"}
              columnGap={2}
            >
              <Trans i18nKey={"invoices.dueDate"}>Due date</Trans>:
              <Typography
                data-testid="invoice-due-date"
                variant={isDesktop ? "body2" : "body4"}
              >
                {getFormattedDate(invoice?.dueDate ?? "")}
              </Typography>
            </Typography>
            <Grid mt={{md: 2, xs: 1}}>
              <Typography
                variant={isDesktop ? "h2" : "body1"}
                display="inline-flex"
                flexWrap={"wrap"}
                columnGap={2}
              >
                <Trans i18nKey={"invoices.invoiceDate"}>Invoice date</Trans>:
                <Typography
                  data-testid="invoice-date"
                  variant={isDesktop ? "body2" : "body4"}
                >
                  {getFormattedDate(invoice?.created ?? "")}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            ml={{md: 9, xs: 0}}
            mt={{xs: 1, md: 0}}
            display="inline-flex"
            flexWrap={"wrap"}
            columnGap={2}
          >
            <Typography variant={isDesktop ? "h2" : "body1"}>
              <Trans i18nKey={"purchases.purchaseCard.status"}>Status:</Trans>
            </Typography>
            {invoice?.accountingTransactionStatus ? (
              <Chip
                label={
                  invoice?.accountingTransactionStatus?.toLowerCase() === "paid"
                    ? t("invoices.statuses.paid")
                    : t("invoices.statuses.notPaid")
                }
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  height: "24px",
                  p: "0 6px",
                  ...getInvoiceStatusColors(
                    invoice?.accountingTransactionStatus ?? "Void",
                  ),
                }}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid mt={{xs: 3}} xs={6} md={1.5}>
          <LoadingButton
            data-testid="btn-download-invoice"
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            endIcon={<></>}
            onClick={handleDownloadPDF}
            loading={loading}
            disabled={!invoice}
          >
            {loading ? <></> : <Trans i18nKey={"invoices.downloadInvoice"}>Download Invoice</Trans>}
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid mt={4} id={"invoiceToPrint"}>
        <InvoiceDetailsInfo item={invoice} />
      </Grid>
      <Grid
        container
        columns={{xs: 6, md: 14}}
        direction={"column"}
        columnSpacing={0}
      >
        {invoice?.accountingTransactionStatus == "Paid" ? (
          <Grid
            display={"block"}
            xs={6}
            md={9}
            lg={6}
            mt={2}
            mb={{xs: 5, md: 8}}
          >
            <Typography variant="h2" mb={3}>
              {t("invoices.paymentInformation")}
            </Typography>
            <Box sx={boxStyle}>
              {payments?.map((payment) => (
                <InvoicePaymentInfo payment={payment} key={payment.paymentId} />
              ))}
            </Box>
          </Grid>
        ) : showSimpleCreditCardForm ? (
          <Grid mb={{xs: 6, md: 8}} maxWidth={"600px"}>
            <Typography variant={"h2"} mb={2}>
              <Trans i18nKey={"delivery.statusBar.paymentMethod"}>
                Payment Methods
              </Trans>
            </Typography>
            <PaymentCardForm
              onSubmit={handlePayInvoiceByCardSubmit}
              submitBtnLabel={t("invoices.payInvoice")}
            />
          </Grid>
        ) : (
          <Grid mb={{xs: 6, md: 8}}>
            <Typography variant={"h2"} mb={{md: 2, xs: 0}}>
              <Trans i18nKey={"delivery.statusBar.paymentMethod"}>
                Payment Methods
              </Trans>
            </Typography>
            <PaymentMethodFragment
              handleNext={undefined}
              invoiceInvisible={true}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              pageTitle={"Invoice Details"}
            ></PaymentMethodFragment>
            <Grid md={1.7} xs={6}>
              <LoadingButton
                fullWidth
                type="button"
                variant="contained"
                color="secondary"
                loading={isLoadingPayInvoice}
                disabled={selectedPaymentMethod == null}
                onClick={handlePayInvoice}
              >
                {t("invoices.payInvoice")}
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
