import React, {useCallback, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Field, useFormikContext} from "formik";
import {TextField} from "formik-mui";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {PurchaseDetailsProps} from "../pages/interfaces";
import DeleteIcon from "@mui/icons-material/Delete";
import {useParams} from "react-router-dom";
import {usePurchaseDeleteSubmit} from "../hooks/usePurchaseDelete";
import ModalForm from "../../../common/Modal/components/modalForm";
import RemovePurchaseModal from "./removePurchaseModal";
import {Alert, LoadingButton} from "@mui/lab";
import {ContactDto, ContactTypes} from "../../../features/contact/contact-api";
import {useGetContacts} from "../hooks/UseGetContacts";
import {EntityTypes} from "../../../features/order/order-api";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {useOrganizationConfig} from "../../../common/OrganizationConfig/useOrganizationConfig";

export default function PurchaseDetails({
  handleNext,
  setIsRequestToShip,
  submitForm,
  onDeletePurchase,
}: PurchaseDetailsProps) {
  const {t} = useTranslation();
  const formikContext = useFormikContext<any>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const {moduleConfig, isLoading: isLoadingConfig} = useOrganizationConfig();

  const params = useParams();
  const orderId = Number(params.purchaseId);
  const {onDelete, isDeleteProcess, isDeleteSuccess} =
    usePurchaseDeleteSubmit(orderId);

  const handleDeletePurchase = useCallback(async () => {
    onDelete({});
    if (onDeletePurchase) await onDeletePurchase();
  }, [onDelete, onDeletePurchase]);

  const [withoutTrackingNumber, setWithoutTrackingNumber] =
    useState<boolean>(false);

  const [vendorValue, setVendorValue] = useState<ContactDto | null | undefined>(
    params.purchaseId
      ? {
          name: formikContext.values?.vendor?.nonContactName,
          contactId: formikContext.values?.vendor?.contactId,
        }
      : null,
  );
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const {contactsData, isLoading, isError, error} = useGetContacts(
    `contactType:${ContactTypes.Vendor}`,
  );

  const showAdditionalTrackingNumbers =
    moduleConfig?.customValues?.purchases?.enableAdditionalTrackingNumbers ===
    true;
  const additionalTrackingNumberTypes =
    moduleConfig?.customValues?.purchases?.additionalTrackingNumberTypes ?? [];
  const maxAdditionalTrackingNumbers =
    moduleConfig?.customValues?.purchases?.maxAdditionalTrackingNumbers ?? 3;

  useEffect(() => {
    if (isError) {
      `${t("toasts.error")}. ${
        error && "status" in error ? (error.data as string) : ""
      }`;
    }
  }, [isLoading]);

  useEffect(() => {
    if (formikContext.values?.vendor?.contactId > 0) {
      setVendorValue({
        name: formikContext.values?.vendor?.nonContactName,
        contactId: formikContext.values?.vendor?.contactId,
      });
    } else {
      setVendorValue(null);
    }
  }, [formikContext.values?.vendor?.contactId]);

  const isCreateMode = (): boolean => orderId === 0;
  return (
    <Box
      sx={{
        border: {xs: 0, md: "1px solid #D8E6FB"},
        borderRadius: {xs: 0, md: "16px"},
        boxShadow: {xs: 0, md: "0px 0px 8px rgba(15, 74, 132, 0.1)"},
        backgroundColor: "white",
        height: "100%",
      }}
    >
      <Grid container columns={{xs: 6, md: 12}} sx={{height: "100%"}}>
        <Grid md />

        <Grid
          xs={6}
          md={10}
          sx={{
            height: "100%",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container direction="column" mb={{xs: 0, md: 5}}>
            <Grid container>
              <Grid container columns={5}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{mt: {xs: 2, md: 5}, mb: 2}}
                >
                  <Trans i18nKey="addPurchasesTitle">
                    Shipping information
                  </Trans>
                </Typography>
              </Grid>
              {!isCreateMode() && isDesktop && (
                <Grid
                  container
                  columns={2}
                  flex={1}
                  sx={{justifyContent: "end", mt: {xs: 1, md: 4}, mb: 2}}
                >
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                    }}
                    sx={{color: "primary.dark"}}
                    data-testid={"btn-delete-item"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            {withoutTrackingNumber ? (
              <Field
                fullWidth
                component={TextField}
                size={isDesktop ? "normal" : "small"}
                name="trackingNumber"
                label={t("orderNumber")}
                placeholder="000000000000"
                data-testid="input-purchase-order-number"
                disabled={formikContext.isSubmitting}
              />
            ) : (
              <Field
                fullWidth
                component={TextField}
                size={isDesktop ? "normal" : "small"}
                name="trackingNumber"
                label={t("trackingNumber")}
                placeholder="000000000000"
                data-testid="input-purchase-tracking-number"
                disabled={formikContext.isSubmitting}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  sx={{color: "primary.light"}}
                  disabled={formikContext.isSubmitting}
                />
              }
              label={t("withoutTrackingNumber")}
              value={withoutTrackingNumber}
              onChange={() => setWithoutTrackingNumber(!withoutTrackingNumber)}
              sx={{mb: 1, userSelect: "none"}}
              data-testid="input-purchase-checkbox"
            />
            {showAdditionalTrackingNumbers && (
              <>
                {formikContext.values?.additionalTrackingNumbers?.length >
                  0 && (
                  <Stack spacing={{xs: 2, md: 3}} mb={3}>
                    {formikContext.values?.additionalTrackingNumbers?.length >
                      0 && (
                      <Alert
                        variant="outlined"
                        severity="warning"
                        sx={{
                          "& .MuiAlert-message": {
                            textAlign: "justify",
                            fontSize: "0.9rem",
                          },
                        }}
                      >
                        <Trans i18nKey={"additionalTrackingNumberWarning"}>
                          Please ensure that tracking numbers entered are
                          specific to the current purchase only. Do not include
                          tracking numbers associated with unrelated purchases,
                          as this could lead to incorrect processing and
                          potential loss of your package. If you are unsure,
                          double-check the tracking information before
                          submitting.
                        </Trans>
                      </Alert>
                    )}
                    {formikContext.values?.additionalTrackingNumbers?.map(
                      (item: any, index: number) => (
                        <Grid container direction={"row"} key={index}>
                          <Grid xs={7} pr={2}>
                            <Field
                              fullWidth
                              component={TextField}
                              size={isDesktop ? "normal" : "small"}
                              name={`additionalTrackingNumbers[${index}].trackingNumber`}
                              label={t("additionalTrackingNumber", {
                                index: index + 1,
                              })}
                              data-testid={`additional-tracking-number-${index}`}
                              disabled={formikContext.isSubmitting}
                            />
                          </Grid>
                          <Grid xs={4}>
                            <Autocomplete
                              options={additionalTrackingNumberTypes ?? []}
                              loading={isLoadingConfig}
                              noOptionsText={null}
                              value={item?.trackingNumberType}
                              onChange={(_: any, value: any) => {
                                formikContext.setFieldValue(
                                  `additionalTrackingNumbers[${index}].trackingNumberType`,
                                  value,
                                );
                              }}
                              renderInput={(params: any) => (
                                <Field
                                  {...params}
                                  fullWidth
                                  component={TextField}
                                  size={isDesktop ? "normal" : "small"}
                                  name={`additionalTrackingNumbers[${index}].trackingNumberType`}
                                  label={t("additionalTrackingNumberType")}
                                  data-testid={`additional-tracking-number-type-${index}`}
                                  disabled={formikContext.isSubmitting}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            xs={1}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            sx={{cursor: "pointer"}}
                            onClick={() => {
                              formikContext.setFieldValue(
                                "additionalTrackingNumbers",
                                formikContext.values.additionalTrackingNumbers.filter(
                                  (_: any, i: number) => i !== index,
                                ),
                              );
                            }}
                          >
                            <DeleteIcon sx={{color: "primary.dark"}} />
                          </Grid>
                        </Grid>
                      ),
                    )}
                  </Stack>
                )}
                {formikContext?.values?.additionalTrackingNumbers?.length <
                  maxAdditionalTrackingNumbers && (
                  <Grid
                    display={"flex"}
                    direction="row"
                    mb={3}
                    sx={{
                      width: "max-content",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    onClick={() => {
                      if (!formikContext.isSubmitting)
                        formikContext.setFieldValue(
                          "additionalTrackingNumbers",
                          [
                            ...formikContext.values.additionalTrackingNumbers,
                            {
                              trackingNumber: "",
                              trackingNumberType: null,
                            },
                          ],
                        );
                    }}
                  >
                    <AddBoxIcon
                      color={"primary"}
                      sx={{marginRight: 1, marginLeft: "-2px"}}
                    />
                    <Typography variant="body1" alignItems="end">
                      <Trans i18nKey={"additionalTrackingNumberBtn"}>
                        Additional tracking number
                      </Trans>
                    </Typography>
                  </Grid>
                )}
              </>
            )}

            <Stack spacing={{xs: 2, md: 3}}>
              <Autocomplete
                options={contactsData?.items ?? []}
                getOptionLabel={(option: any) => option.name ?? ""}
                loading={isLoading}
                isOptionEqualToValue={(option, value) =>
                  option?.name != value?.name
                }
                noOptionsText={null}
                value={vendorValue}
                freeSolo={true}
                forcePopupIcon={true}
                onInputChange={(_, value) => {
                  const vendor = {
                    orderEntitySequence: 0,
                    entityType: EntityTypes.Vendor,
                    customValues: {},
                    nonContactName: value,
                    orderEntityId: 0,
                  };
                  formikContext.setFieldValue("orderEntities", []);
                  formikContext.setFieldValue("vendor", vendor);
                }}
                onChange={(_: any, value: any) => {
                  const entity = {
                    orderEntitySequence: 0,
                    entityType: EntityTypes.Vendor,
                    nonContactName: value?.name,
                    orderEntityId: 0,
                    contactId: value?.contactId,
                    customValues: {},
                  };
                  formikContext.setFieldValue("vendor", entity);
                  formikContext.setFieldValue(
                    "orderEntities",
                    value ? [entity] : [],
                  );
                }}
                renderInput={(params: any) => (
                  <Field
                    {...params}
                    component={TextField}
                    fullWidth
                    variant="outlined"
                    size={isDesktop ? "normal" : "small"}
                    label={t("vendor")}
                    placeholder={t("sellerPlaceholder")}
                    data-testid="input-purchase-seller"
                    name="vendor.nonContactName"
                    disabled={formikContext.isSubmitting}
                  />
                )}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                {isDesktop ? (
                  <DesktopDatePicker
                    open={isCalendarOpen}
                    onClose={handleCalendarClose}
                    components={{
                      OpenPickerIcon: CalendarTodayIcon,
                    }}
                    label={t("expectedDate")}
                    inputFormat="MM/DD/YYYY"
                    value={expDateValue}
                    data-testid="input-purchase-date-desktop"
                    onChange={handleDateChange}
                    renderInput={(params: any) => (
                      <DateTextField
                        {...params}
                        onClick={handleDateFieldClick}
                        onFocus={handleDateFieldFocus}
                        onBlur={handleDateFieldBlur}
                        InputLabelProps={{
                          shrink:
                            expDateValue ||
                            isCalendarOpen ||
                            isDateFieldFocused,
                        }}
                      />
                    )}
                    disabled={formikContext.isSubmitting}
                  />
                ) : (
                  <MobileDatePicker
                    label={t("expectedDate")}
                    inputFormat="MM/DD/YYYY"
                    value={expDateValue}
                    data-testid="input-purchase-date-mobile"
                    onChange={handleDateChange}
                    renderInput={(params: any) => (
                      <DateTextField
                        {...params}
                        size={isDesktop ? "normal" : "small"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              <Icon>
                                <CalendarTodayIcon />
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    disabled={formikContext.isSubmitting}
                  />
                )}
              </LocalizationProvider> */}
              <Field
                fullWidth
                component={TextField}
                size={isDesktop ? "normal" : "small"}
                name="carrier.nonContactName"
                label={t("carrier")}
                placeholder={t("carrierPlaceholder")}
                data-testid="input-purchase-carrier"
                disabled={formikContext.isSubmitting}
              />
            </Stack>
          </Grid>
          {!isDesktop && (
            <Grid sx={{mb: "72px"}}>
              {!isCreateMode() && (
                <Button
                  sx={{
                    p: 1,
                    mt: 2,
                  }}
                  fullWidth
                  type="button"
                  variant="outlined"
                  color="error"
                  data-testid="btn-delete-item-purchase"
                  onClick={handleOpen}
                >
                  <Trans i18nKey="delete">Delete</Trans>
                </Button>
              )}
              <Button
                fullWidth
                type="button"
                variant="outlined"
                color="primary"
                data-testid="btn-next-to-items"
                onClick={handleNext}
                sx={{
                  p: 1,
                  position: "sticky",
                  mt: 2,
                }}
              >
                <Trans i18nKey="next">Next</Trans>
              </Button>
              <LoadingButton
                sx={{p: 1, mt: 1}}
                fullWidth
                type="button"
                variant="contained"
                color="primary"
                data-testid="btn-save-purchase"
                disabled={formikContext?.isSubmitting}
                loading={formikContext?.isSubmitting}
                loadingPosition="end"
                onClick={() =>
                  submitForm().then(() => setIsRequestToShip(false))
                }
                endIcon={<></>}
              >
                <Trans i18nKey="save">Save</Trans>
              </LoadingButton>
            </Grid>
          )}
        </Grid>

        <Grid md />
        <ModalForm open={open} setOpen={setOpen}>
          <RemovePurchaseModal
            closeModal={closeModal}
            onDelete={handleDeletePurchase}
            isDeleteSucces={isDeleteSuccess}
            isDeleteProcess={isDeleteProcess}
          />
        </ModalForm>
      </Grid>
    </Box>
  );
}
