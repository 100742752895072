import * as yup from "yup";

const onlyNumeric = /^\d*\.?\d*$/;
const positiveInteger = /^[1-9]\d*$/;

export const getPurchaseSchema = (t: any) => {
  const isEnglish = (value: any) => /^[^\u0400-\u04FF]+$/u.test(value);

  const schema = yup.object().shape({
    trackingNumber: yup
      .string()
      .transform((x) => x.trim())
      .required(t("validation.editPurchases.trackingNumberRequired"))
      .max(50, t("validation.editPurchases.trackingNumberLength")),
    vendor: yup.object().shape({
      nonContactName: yup
        .string()
        .required(t("validation.editPurchases.vendorRequired")),
    }),
    additionalTrackingNumbers: yup.array().of(
      yup.object().shape({
        trackingNumber: yup
          .string()
          .transform((x) => x.trim())
          .required(t("validation.editPurchases.trackingNumberRequired"))
          .max(50, t("validation.editPurchases.trackingNumberLength")),
        trackingNumberType: yup
          .string()
          .required(t("validation.editPurchases.trackingNumberTypeRequired"))
          .nullable(),
      }),
    ),
    commodities: yup.array().of(
      yup.object().shape({
        description: yup
          .string()
          .required(t("validation.editPurchases.descriptionRequired"))
          .max(700, t("validation.editPurchases.descriptionLength"))
          .test(
            "is-english",
            t("validation.editPurchases.descriptionLanguage"),
            isEnglish,
          ),
        note: yup.string().max(700, t("validation.editPurchases.noteLength")),
        quantity: yup
          .string()
          .typeError(t("validation.declaration.quantityPositiveInteger"))
          .matches(
            positiveInteger,
            t("validation.declaration.quantityPositiveInteger"),
          )
          .required(t("validation.declaration.quantityRequired")),
        unitaryValue: yup
          .string()
          .matches(onlyNumeric, t("validation.code.digits"))
          .required(t("validation.declaration.unitaryValueRequired")),
        weight: yup.string().matches(onlyNumeric, t("validation.code.digits")),
      }),
    ),
  });

  return schema;
};

export const validatePositiveIntegerNumberInput = (e: KeyboardEvent) => {
  if (
    (e.key < "0" || e.key > "9") &&
    e.key !== "ArrowLeft" &&
    e.key !== "ArrowRight" &&
    e.key !== "Delete" &&
    e.key !== "Backspace" &&
    e.key !== "Shift" &&
    e.key !== "Control" &&
    e.key !== "Tab" &&
    e.key !== "End" &&
    e.key !== "Home" &&
    ((e.key !== "a" && e.key !== "c" && e.key !== "x" && e.key !== "z") ||
      !e.getModifierState("Control")) &&
    ((e.key !== "Home" &&
      e.key !== "End" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight") ||
      !e.getModifierState("Shift"))
  ) {
    e.preventDefault();
  }
};
